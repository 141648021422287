import JsBarcode from "jsbarcode";
import Canvas from "canvas";
import imageCtrl from "../controllers/imageCtrl";
import Store from "../store";
import { checkProtectionAuthorityNormal } from "../../src/controllers/protection";
// import { luckysheet } from "../core";
// import { modelHTML } from "../controllers/constant";

function insertQrcodeSign() {
  alert("开发中，请期待");
}

function insertBarcodeSign() {
  var { createCanvas } = Canvas;
  var canvas = createCanvas();

  // 设置的条形码参数
  let options = {
    text: "123456789",
    displayValue: true,
    fontSize: 12,
    height: 40,
  };
  //987654321 是条形码的携带的信息，和option中的text显示信息要区分
  //options:是条形码的配置信息
  JsBarcode(canvas, "987654321", options);
  let img = new Image();
  let url = canvas.toDataURL("image/png");
  img.src = url;

  if (
    !checkProtectionAuthorityNormal(
      Store.currentSheetIndex,
      "editObjects",
      false
    )
  ) {
    return;
  }
  let file = {
    src: img.src,
    left: 0,
    top: 0,
    originWidth: img.width || options.width,
    originHeight: img.height || options.height,
    imageType: "barCode",
  };
  imageCtrl.addImgItem(file);

  // luckysheet.setluckysheet_has_signimage(true);
}

export { insertQrcodeSign, insertBarcodeSign };
